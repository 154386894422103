import api from '../axios'
import config from '@/constants/config'

const resource = '/api/mp'

export default {

  getInvoicesList :(page, paginate, filterQuery) => api.get(`${resource}/invoices?page=${page}&paginate=${paginate}&${filterQuery}`),
  getInvoiceDetails:(id) => api.get(`${resource}/invoices/${id}`),
  getInvoiceSummaryDetails:() => api.get(`${resource}/invoices/summary`),
  markPaid:(id) => api.post(`${resource}/invoices/${id}/mark-as-paid`),
  markUnPaid:(id) => api.post(`${resource}/invoices/${id}/mark-as-outstanding`),

}






