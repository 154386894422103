var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invoice-wrapper" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xl-1 col-md-2" },
        [
          _c(
            "b-button",
            {
              staticClass:
                "shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white",
              attrs: { variant: "white" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [
              _c("feather-icon", {
                staticClass: "txt-green",
                attrs: { icon: "ChevronLeftIcon", size: "20" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "col-xl-9 col-md-7" }, [
        _c("div", { staticClass: "card invoice-preview-card" }, [
          _c("div", { staticClass: "col-md-1 logo-wrapper" }, [
            _c("p", { staticClass: "in-ribbon" }, [
              _vm._v(_vm._s(_vm.invoiceDetails.state.toUpperCase()))
            ])
          ]),
          _c("div", { staticClass: "card-body invoice-padding pb-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 align-items-center"
              },
              [
                _c("div", { staticClass: "text-center text-md-left" }, [
                  _c(
                    "h2",
                    { staticClass: "txt-black font-weight-bolder mb-2" },
                    [_vm._v("Invoice #" + _vm._s(_vm.invoiceDetails.id))]
                  ),
                  _c("p", { staticClass: "card-text mb-25" }, [
                    _vm._v(" Issue Date: "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.invoiceDetails.issue_date))
                    ])
                  ]),
                  _c("p", { staticClass: "card-text mb-0" }, [
                    _vm._v(" Due Date: "),
                    _c("strong", [_vm._v(_vm._s(_vm.invoiceDetails.due_date))])
                  ])
                ]),
                _c("div", { staticClass: "mt-md-0 mt-2" }, [
                  _c("h5", { staticClass: "txt-black font-weight-bolder" }, [
                    _vm._v(
                      _vm._s(
                        _vm.invoiceDetails.user.first_name +
                          " " +
                          _vm.invoiceDetails.user.last_name
                      )
                    )
                  ]),
                  _c("p", [
                    _c("a", { attrs: { href: "" } }, [
                      _vm._v(_vm._s(_vm.invoiceDetails.user.email))
                    ])
                  ])
                ])
              ]
            )
          ]),
          _c("hr", { staticClass: "invoice-spacing" }),
          _c("div", { staticClass: "card-body invoice-padding pt-0" }, [
            _c("div", { staticClass: "row invoice-spacing py-2" }, [
              _c(
                "div",
                { staticClass: "col-xl-6 col-12 text-center text-md-left" },
                [
                  _c(
                    "h4",
                    { staticClass: "mb-2 txt-black font-weight-bolder" },
                    [_vm._v("Invoice To:")]
                  ),
                  _c("p", { staticClass: "txt-black font-weight-bolder" }, [
                    _vm._v(_vm._s(_vm.invoiceDetails.practice.name))
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.invoiceDetails.practice.address) +
                        ", " +
                        _vm._s(_vm.invoiceDetails.practice.city) +
                        ", " +
                        _vm._s(_vm.invoiceDetails.practice.postal_code)
                    ),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.invoiceDetails.practice.phone_number)
                    ),
                    _c("br"),
                    _c("a", { attrs: { href: "" } }, [
                      _vm._v(_vm._s(_vm.invoiceDetails.practice.email))
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end col-xl-6 col-12"
                },
                [
                  _c("div", { staticClass: "invoice-price" }, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "txt-price txt-black font-weight-bolder mb-2"
                      },
                      [
                        _vm._v(
                          "Balance Due: £" +
                            _vm._s(_vm.invoiceDetails.total_invoice_cost)
                        )
                      ]
                    ),
                    _c("div", { staticClass: "table-responsive tbl-nl" }, [
                      _c("table", { staticClass: "table b-table" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "pr-1" }, [
                              _vm._v("Bank name:")
                            ]),
                            _c("td", [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.invoiceDetails.bank_name))
                              ])
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "pr-1" }, [
                              _vm._v("IBAN:")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.invoiceDetails.bank_account_number)
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "pr-1" }, [
                              _vm._v("Sort code:")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.invoiceDetails.bank_sort_code))
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "table-responsive tbl-nl" }, [
            _c(
              "table",
              {
                staticClass: "table b-table tbl-invoice",
                attrs: {
                  "aria-busy": "false",
                  "aria-colcount": "4",
                  role: "table"
                }
              },
              [
                _vm._m(0),
                _c(
                  "tbody",
                  { attrs: { role: "rowgroup" } },
                  [
                    _vm._l(_vm.invoiceDetails.invoice_shift, function(shift) {
                      return _c("tr", { attrs: { role: "row" } }, [
                        _c(
                          "td",
                          { attrs: { "aria-colindex": "1", role: "cell" } },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "card-text font-weight-bold mb-25"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.momentFormat(
                                        shift.shift.start,
                                        "ddd DD-MM-YYYY"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "td",
                          { attrs: { "aria-colindex": "2", role: "cell" } },
                          [_vm._v("£" + _vm._s(shift.shift.locum_rate) + ".00")]
                        ),
                        _c(
                          "td",
                          { attrs: { "aria-colindex": "2", role: "cell" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.timeDifferentHours(
                                  shift.shift.start,
                                  shift.shift.end
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "td",
                          { attrs: { "aria-colindex": "4", role: "cell" } },
                          [
                            _vm._v(
                              "£" +
                                _vm._s(
                                  _vm.totalShiftCost(
                                    shift.shift.start,
                                    shift.shift.end,
                                    shift.shift.locum_rate
                                  )
                                )
                            )
                          ]
                        )
                      ])
                    }),
                    _c("tr", { attrs: { role: "row" } }, [
                      _c("td", {
                        attrs: { "aria-colindex": "1", role: "cell" }
                      }),
                      _c("td", {
                        attrs: { "aria-colindex": "2", role: "cell" }
                      }),
                      _c(
                        "td",
                        {
                          staticClass: "py-2",
                          attrs: { "aria-colindex": "2", role: "cell" }
                        },
                        [_vm._v("Total:")]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "font-weight-bolder",
                          attrs: { "aria-colindex": "4", role: "cell" }
                        },
                        [
                          _vm._v(
                            "£" + _vm._s(_vm.invoiceDetails.total_invoice_cost)
                          )
                        ]
                      )
                    ])
                  ],
                  2
                )
              ]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "col-xl-2 col-md-3" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-end" },
          [
            _c(
              "b-button",
              {
                staticClass:
                  "shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white",
                staticStyle: { "margin-right": "4px" },
                attrs: { variant: "white" }
              },
              [
                _c("feather-icon", {
                  staticClass: "txt-green",
                  attrs: { icon: "DownloadIcon", size: "20" }
                })
              ],
              1
            ),
            _c(
              "b-button",
              {
                staticClass:
                  "shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white",
                staticStyle: { "margin-right": "4px" },
                attrs: { variant: "white" }
              },
              [
                _c("feather-icon", {
                  staticClass: "txt-green",
                  attrs: { icon: "PrinterIcon", size: "20" }
                })
              ],
              1
            ),
            _c(
              "b-button",
              {
                staticClass:
                  "shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white",
                staticStyle: { "margin-right": "4px" },
                attrs: { variant: "white" }
              },
              [
                _c("feather-icon", {
                  staticClass: "txt-green",
                  attrs: { icon: "TrashIcon", size: "20" }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { attrs: { role: "rowgroup" } }, [
      _c("tr", { attrs: { role: "row" } }, [
        _c(
          "th",
          {
            attrs: { "aria-colindex": "1", role: "columnheader", scope: "col" }
          },
          [_c("div", [_vm._v("Shift Date")])]
        ),
        _c(
          "th",
          {
            attrs: { "aria-colindex": "2", role: "columnheader", scope: "col" }
          },
          [_c("div", [_vm._v("Hourly Rate")])]
        ),
        _c(
          "th",
          {
            attrs: { "aria-colindex": "3", role: "columnheader", scope: "col" }
          },
          [_c("div", [_vm._v("HOURS")])]
        ),
        _c(
          "th",
          {
            attrs: { "aria-colindex": "4", role: "columnheader", scope: "col" }
          },
          [_c("div", [_vm._v("PRICE")])]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }